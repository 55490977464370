import React, { useEffect, useState } from 'react';
import './index.css';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import ProductDetail from '../../Components/ProductDetailWrapper/ProductDetail';
import CarCarousel from '../../Components/ProductDetailWrapper/CarCarousel';
// import FeaturedVehicles from '../../Components/FeatureWrapper/FeaturedVehicles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
// import carDummyData from '../../fixtures/carsDummyData.json';

import { get_all_cars, get_car_by_id } from '../../API/index';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
// import { format } from 'date-fns';

export default function ProductDetailPage({ ...props }) {
  const { id } = useParams();
  // const data = carDummyData.find((e) => e.id === id);

  const [allCars, setAllCars] = useState(null);
  const [carData, setCarData] = useState(null);

  const format_date = (date, time) => {
    return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  };

  useEffect(() => {
    const start_date = format_date(props.fromDate, props.fromTime);
    const end_date = format_date(props.untilDate, props.untilTime);
    get_car_by_id(id, start_date, end_date).then((res) => {
      if (res.status === 200 || res.status === 304) {
        // console.log(res.data);
        setCarData(res.data);
      } else {
        setCarData([]);
      }
      get_all_cars().then((res) => {
        if (res.status === 200 || res.status === 304) {
          setAllCars({
            pagination: {
              count: res?.data?.count,
              total_pages: res?.data?.total_pages,
              page_number: res?.data?.page_number,
            },
            data: res?.data?.results,
          });
        } else {
          setAllCars([]);
        }
      });
    });
    window.localStorage.setItem('car_id', id);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
console.log("allcars", carData)
  return (
    <div>
      <NavBar />
      <div className='product_detail_page' >
        <FeatureToast step={3} props={props} />
        {carData && <ProductDetail data={carData} props={props} />}
        {allCars ? (
          <>
          <CarCarousel
            allCars={allCars}
            untilDate={props.untilDate}
            fromDate={props.fromDate}
            untilTime={props.untilTime}
            fromTime={props.fromTime}
            bookingPeriod={props.bookingPeriod}
          />
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </div>
      <Footer />
    </div>
  );
}
