import React, { useEffect } from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import './index.css';
import { InView } from 'react-intersection-observer';
// import { CircularProgress } from '@material-u';
import { Pagination, MenuItem, CircularProgress } from '@mui/material';

export default function ChooseVehicle({
  fromDate,
  fromTime,
  untilDate,
  untilTime,
  filter,
  bookingPeriod,
  deliveryCharge,
  allCars,
  getAllCars,
}) {
  console.log('🚀OUTPUT --> allCars:', allCars);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const count = Math.ceil(allCars?.length / PER_PAGE);
  // const _DATA = usePagination(allCars, PER_PAGE);

  const handlePageChange = (page_no, prev_data) => {
    getAllCars(page_no, prev_data);
  };

  const sortFunc = (data) => {
    let unavailable_cars = data?.filter(
      (a) =>
        a.unavailable == true &&
        a.trip_preference?.min_trip_duration <= bookingPeriod
    );
    unavailable_cars = unavailable_cars?.sort((value1, value2) =>
      filter.order_name === '-daily_price'
        ? value2.daily_price - value1.daily_price
        : value1.daily_price - value2.daily_price
    );
    let available_cars = data?.filter(
      (a) =>
        a.unavailable == false &&
        a.trip_preference?.min_trip_duration <= bookingPeriod
    );
    available_cars = available_cars?.sort((value1, value2) =>
      filter.order_name === '-daily_price'
        ? value2.daily_price - value1.daily_price
        : value1.daily_price - value2.daily_price
    );
    let min_duration_not_met_cars = data?.filter(
      (a) => a.trip_preference?.min_trip_duration > bookingPeriod
    );
    min_duration_not_met_cars = min_duration_not_met_cars?.sort(
      (value1, value2) =>
        filter.order_name === '-daily_price'
          ? value2.daily_price - value1.daily_price
          : value1.daily_price - value2.daily_price
    );
    console.log(filter, available_cars);
    // Search results display order:
    // available -> trip duration minimum not met -> unavailable
    console.log('----------unavailable cars---------------', allCars)
    console.log('----------available cars---------------', available_cars)
    console.log('----------min dur cars---------------', min_duration_not_met_cars)
    return available_cars
      ?.concat(min_duration_not_met_cars)
      .concat(unavailable_cars);
  };

  return (
    <div className='featured_vehicles'>
      <div className='heading'>Choose a Vehicle</div>
      <div className='featured_card_wrapper'>
        {allCars?.data?.length === 0 ? (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: 22,
            }}
          >
            No Vehicles Available
          </div>
        ) : (
          // _DATA.currentData()

          // .filter((e) => e.vehicle_status === 'prepped')
          sortFunc(allCars?.data)?.map((car, index) => (
            <CarCard
              id={car.id}
              key={car.id}
              img={car.car_images[0]?.image}
              title={car.title}
              similarTo={''}
              type={car.type}
              model={car.model}
              make={car.make}
              car={car}
              noOfPeople={car.number_of_seats}
              noOfBags={car.number_of_bags}
              driveTrain={car.drive_train}
              dailyRate={car.daily_price}
              totalCost={car.total_price}
              cardPath={`/car/${car.id}`}
              selectPath={`/info/${car.id}`}
              fromDate={fromDate}
              fromTime={fromTime}
              untilDate={untilDate}
              untilTime={untilTime}
              bookingPeriod={bookingPeriod}
              unavailable={car.unavailable}
              deliveryCharge={deliveryCharge}
            />
          ))
        )}
      </div>
      {allCars?.pagination?.page_number < allCars?.pagination?.total_pages && (
        <InView
          as='div'
          triggerOnce={false}
          onChange={(inView, entry) => {
            if (inView) {
              handlePageChange(
                allCars?.pagination?.page_number + 1,
                allCars?.data
              );
              // setPrevData(allCars?.data)
              // setPage(allCars?.pagination?.page_number + 1)
            }
          }}
        >
          <MenuItem value='disabled' disabled>
            <CircularProgress color='success' />
          </MenuItem>
        </InView>
      )}
      {/* {allCars?.data?.length !== 0 && (
        <Pagination
          className='chooseVehicle_pagination'
          // variant='outlined'
          shape='circular'
          count={allCars?.pagination?.total_pages}
          page={allCars?.pagination?.page_number}
          onChange={(event, value) => handlePageChange(value)}
        />
      )} */}
    </div>
  );
}
